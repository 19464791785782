
import { podcastUrl, podcastEpisodesUrl, mapFeedToEpisodes, slugify } from '~/utils'
import { EXTERNAL_LINKS } from '~/constants'

export default {
  props: {
    podcastId: {
      type: String,
      default: '',
    },
    podcastData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    podcastTopic: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  asyncData ({ store, route, params: { podcast } }) {
    store.dispatch('breadcrumbs/mirror', {
      path: route.path,
      mirror: `/podcasts/${podcast}`,
    })
  },
  data () {
    return {
      externalLinks: EXTERNAL_LINKS,
      topic: {},
      topicTeaser: {},
      topicArticles: [],
      topicPodcasts: [],
      podcastFeed: [],
      podcastFeedLimit: 6,
      podcastArticles: [],
    }
  },
  async fetch () {
    if (this.podcastId) {
      // Get podcast feed of episodes
      const { data: feed } = await this.$api.podcasts.getAllNested(
        'feed',
        this.podcastId,
        {
          limit: 20,
        },
      )

      const p = this.podcastData
      const podcast = {
        ...(p?.title && { slug: slugify(p.title) }),
        ...(p.title && { title: p.title }),
        ...(p.logo && { imageUrl: p.logo }),
        ...(p?.title && { sharingUrl: podcastUrl(p) }),
        ...(p?.title && { episodesUrl: podcastEpisodesUrl(p) }),
      }
    
      this.podcastFeed = mapFeedToEpisodes(feed, podcast)

      // Get podcast articles
      const { data: { items: podcastArticles = [] } = {} } = await this.$api.podcasts.getAllNested(
        'articles',
        this.podcastId,
        {
          offset: 0,
          limit: 8,
        },
      )
      this.podcastArticles = podcastArticles
    }

    if (this.podcastTopic?.id) {
      // Get topic
      const { data: topicResult } = await this.$api.topics.getOne(this.podcastTopic.id)
      this.topic = topicResult?.item

      // Get related articles from topic
      const { data:  { items: topicArticles = [] } } = await this.$api.topics.getAllNested(
        'articles',
        this.podcastTopic.id,
        {
          offset: 0,
          limit: 4,
        },
      )
      this.topicArticles = topicArticles

      // Get most recent teaser for this topic
      const { data: { items: topicTeasers = [] } = {} } = await this.$api.topics.getAllNested(
        'teasers',
        this.topic.id,
        {
          offset: 0,
          limit: 1,
        },
      )
      this.topicTeaser = topicTeasers[0]

      const { data: podcastsResult } = await this.$api.topics.getAllNested(
        'podcasts',
        this.podcastTopic.id,
        {
          offset: 0,
          limit: 4,
        },
      )
      this.topicPodcasts = podcastsResult?.items
    }
  },
  computed: {
    title () {
      return this.podcastData.title
    },
    hasTopicArticles () {
      return this.topicArticles && this.topicArticles.length > 0
    },
    hasTopicPodcasts () {
      return this.topicPodcasts && this.topicPodcasts.length > 0
    },
    allPodcasts () {
      return this.podcastFeed?.slice(0, this.podcastFeedLimit)
    },
    lastPodcasts () {
      return this.podcastFeed?.slice(0, 3)
    },
    highlightedPodcast () {
      const feed = this.podcastFeed
      return feed?.slice((feed?.length - 1), (feed?.length))
    },
    highlightedEpisodeDuration () {
      return this.getEpisodeDuration(this.highlightedPodcast[0])
    },
    hasTopic () {
      return Object.keys(this.topic).length > 0
    },
  },
  methods: {
    getEpisodeDuration (podcast) {
      return podcast?.npo?.duration || ''
    },
    playEpisode (episode) {
      this.$store.dispatch('podcastPlayer/addToQueue', episode)
    },
  },
}
