
export default {
  name: 'SectionTitle',
  props: {
    to: {
      type: String,
      default: null,
      required: true,
    },
    moreLabel: {
      type: String,
      default: 'Meer',
    },
  },
  data: () => {
    return {
      isHover: false,
    }
  },
}
